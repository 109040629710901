import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Clear, Search, Login, RestartAlt } from '@mui/icons-material';
import { useFirebaseContext } from '../lib/firebase-context';
import { Kid } from '../lib/types';
import { arrayRemove, arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { datesCollectionRef } from '../lib/firebase';
import CheckInDate from './check-in-date';
import CheckInDates from './check-in-dates';

const KidItem = ({ kid }: { kid: Kid }) => {
  const { activeCheckInDate, groups } = useFirebaseContext();
  const [isLoading, setIsLoading] = useState(false);

  const isCheckedIn = useMemo(() =>
    activeCheckInDate?.checkedInRefs?.includes(kid.ref),
    [activeCheckInDate, kid.ref]
  );

  const toggleCheckIn = useCallback(async () => {
    if (!activeCheckInDate) return;
    setIsLoading(true);
    try {
      await updateDoc(doc(datesCollectionRef, activeCheckInDate.ref), {
        checkedInRefs: isCheckedIn ? arrayRemove(kid.ref) : arrayUnion(kid.ref),
      });
    } catch (error) {
      console.error('Error checking in', error);
    }
    setIsLoading(false);
  }, [isCheckedIn, activeCheckInDate, kid.ref]);

  const showParentInfo = useMemo(() => Boolean(kid.emergency_contact), [kid.emergency_contact]);

  const groupName = useMemo(() =>
    groups.find(group => group.ref === kid.group_id)?.group_name || 'No group',
    [kid.group_id, groups]
  );

  return (
    <ListItem sx={({ palette }) => ({ ':hover': { background: palette.action.hover } })}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <Box width={180}>
          {kid.first_name} {kid.last_name}
        </Box>
        {showParentInfo && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography component='small' textAlign='center'>
              {kid.emergency_contact}
            </Typography>
          </Box>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            component='small'
            sx={{ width: 200, textAlign: 'center', mr: 2 }}
          >
            {groupName}
          </Typography>
          {activeCheckInDate && (
            <Button
              variant='outlined'
              disabled={isLoading} size='small'
              sx={{ minWidth: '120px' }}
              onClick={toggleCheckIn}
              startIcon={isCheckedIn ? <RestartAlt /> : <Login />}
              color={isCheckedIn ? 'warning' : 'success'}
            >
              {isCheckedIn ? 'Reset' : 'Present'}
            </Button>
          )}
        </Box>
      </Box>
    </ListItem>
  )
};

const filterCallback = (kid: Kid, searchQuery: string) => {
  if (!searchQuery) return true;
  return `${kid.first_name} ${kid.last_name}`.toLowerCase().includes(searchQuery.toLowerCase())
};

const ALL_GROUPS_REF = 'ALL_GROUPS';

const KidsList = () => {
  const { kids, groups } = useFirebaseContext();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredKids, setFilteredKids] = useState<Kid[]>(kids);
  const [groupRef, setGroupRef] = useState(ALL_GROUPS_REF);

  useEffect(() => {
    const filteredByGroup = groupRef === ALL_GROUPS_REF
      ? kids
      : kids.filter(kid => kid.group_id === groupRef);
    const filteredBySearchQuery = filteredByGroup.filter(kid => filterCallback(kid, searchQuery));
    setFilteredKids(filteredBySearchQuery);
  }, [kids, searchQuery, groupRef]);

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  }, [setSearchQuery]);

  const handleClearSearch = useCallback(() => {
    setSearchQuery('');
    setFilteredKids(kids);
  }, [setSearchQuery, kids]);

  return (
    <>
      <CheckInDates />
      <CheckInDate />
      <Box sx={{ display: 'flex', mt: 3 }}>
        <TextField
          value={searchQuery}
          onChange={handleSearch}
          label="Search"
          fullWidth
          InputProps={{
            startAdornment: (<InputAdornment position="start"><Search /></InputAdornment>),
            endAdornment: searchQuery.length ? (
              <IconButton onClick={handleClearSearch}><Clear /></IconButton>
            ) : null,
          }}
          variant="outlined"
        />
      </Box>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="group-select-label">Group</InputLabel>
        <Select
          labelId="group-select-label"
          id="group-select"
          value={groupRef}
          label="Group"
          onChange={(e) => setGroupRef(e.target.value)}
        >
          <MenuItem value={ALL_GROUPS_REF}>All groups</MenuItem>
          {groups.map(group => (
            <MenuItem key={group.ref} value={group.ref}>
              {group.group_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <List>
        {filteredKids.length ?
          filteredKids.map((kid) => (
            <KidItem key={kid.ref} kid={kid} />
          )) :
          <Typography align='center' sx={{ mt: 2 }}>No results</Typography>
        }
      </List>
    </>
  );
}

export default KidsList;
