import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'
import { useFirebaseContext } from '../lib/firebase-context';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Check, Clear, DoNotDisturb, Search } from '@mui/icons-material';
import { Kid } from '../lib/types';

const filterCallback = (kid: Kid, searchQuery: string) => {
  if (!searchQuery) return true;
  return `${kid.first_name} ${kid.last_name}`.toLowerCase().includes(searchQuery.toLowerCase())
};

const Stats = () => {
  const { checkInDates, kids, groups } = useFirebaseContext();

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredKids, setFilteredKids] = useState<Kid[]>([]);
  const [groupRef, setGroupRef] = useState('');

  useEffect(() => {
    if (groups) {
      setGroupRef(groups[0].ref);
    }
  }, [groups]);

  useEffect(() => {
    const filteredByGroup = kids.filter(kid => kid.group_id === groupRef);
    const filteredBySearchQuery = filteredByGroup.filter(kid => filterCallback(kid, searchQuery));
    setFilteredKids(filteredBySearchQuery);
  }, [kids, searchQuery, groupRef]);

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  }, [setSearchQuery]);

  const handleClearSearch = useCallback(() => {
    setSearchQuery('');
    setFilteredKids(kids);
  }, [setSearchQuery, kids]);

  const dates = useMemo(() =>
    checkInDates.map(checkInDate => checkInDate.date.toDate().toLocaleDateString(undefined, {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
    })),
    [checkInDates]
  );

  const kidsData = useMemo(() => {
    const data = filteredKids.map(kid => ({
      ref: kid.ref,
      name: `${kid.first_name} ${kid.last_name}`,
      checkIns: checkInDates.map(checkInDate => checkInDate.checkedInRefs?.includes(kid.ref) ?? false),
    }));

    return data;
  }, [filteredKids, checkInDates]);

  return (
    <>
      <Box sx={{ display: 'flex', mt: 3 }}>
        <TextField
          value={searchQuery}
          onChange={handleSearch}
          label="Search"
          fullWidth
          InputProps={{
            startAdornment: (<InputAdornment position="start"><Search /></InputAdornment>),
            endAdornment: searchQuery.length ? (
              <IconButton onClick={handleClearSearch}><Clear /></IconButton>
            ) : null,
          }}
          variant="outlined"
        />
      </Box>
      <FormControl fullWidth sx={{ my: 2 }}>
        <InputLabel id="group-select-label">Group</InputLabel>
        <Select
          labelId="group-select-label"
          id="group-select"
          value={groupRef}
          label="Group"
          onChange={(e) => setGroupRef(e.target.value)}
        >
          {groups.map(group => (
            <MenuItem key={group.ref} value={group.ref}>
              {group.group_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              {dates.map(date => <TableCell key={date} align="right">{date}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {kidsData.map(kid => (
              <TableRow key={kid.ref} hover>
                <TableCell component="th" scope="row">
                  {kid.name}
                </TableCell>
                {kid.checkIns.map((checkIn, index) => (
                  <TableCell key={index} align="right">
                    {checkIn ? <Check color='success' /> : <DoNotDisturb color='error' />}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Stats;
