import { useMemo } from 'react';
import {
  Alert,
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import { useFirebaseContext } from '../lib/firebase-context';

const CheckInDate = () => {
  const { activeCheckInDate } = useFirebaseContext();

  const formattedDate = useMemo(() => !activeCheckInDate?.date ? '' :
    activeCheckInDate?.date.toDate().toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }), [activeCheckInDate?.date]);

  if (!activeCheckInDate) {
    return <Alert severity="error">Please contact admin to activate a check in date</Alert>;
  }

  return (
    <Card>
      <CardContent>
        <Typography textAlign='center'>
          Active check-in date
        </Typography>
        <Typography variant='h3' textAlign='center'>
          {formattedDate}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default CheckInDate;
